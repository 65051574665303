import React, { useEffect, useState } from 'react'
import './Skills.css'
import {AiOutlineHtml5} from 'react-icons/ai'
import {BiLogoCss3} from 'react-icons/bi'
import {SiJavascript} from 'react-icons/si'
import {RiReactjsLine} from 'react-icons/ri'
import {FaJava} from 'react-icons/fa'

import {CircularProgressbar,buildStyles} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css';


const Skills = () => {

const [percentage,setPercentage] = useState (0);
const [percentage2,setPercentage2] = useState (0);
const [percentage3,setPercentage3] = useState (0);
const [percentage4,setPercentage4] = useState (0);

useEffect(()=>{
  setTimeout (()=>{
    if (percentage < 90){
      setPercentage(percentage+1)
    }
  },50)
},[percentage])

useEffect(()=>{
  setTimeout (()=>{
    if (percentage2 < 80){
      setPercentage2(percentage2+1)
    }
  },50)
},[percentage2])

useEffect(()=>{
  setTimeout (()=>{
    if (percentage3 < 85){
      setPercentage3(percentage3+1)
    }
  },50)
},[percentage3])

useEffect(()=>{
  setTimeout (()=>{
    if (percentage4 < 95){
      setPercentage4(percentage4+1)
    }
  },50)
},[percentage4])

  return (
    <div className='s-container' id='skillpage'>
        <div className='s-heading'>
            <span>My </span>
            <span className='skills-name'>   Skills</span>
        </div>
        <div className='body'>
           <div className="s-left">
            <div>
              <h2>
                <abbr title=''>Technical Skills</abbr>
               </h2>
             </div>

            <div>
            <div className='html'>
                 <div className="icons">
                   <AiOutlineHtml5/>
                 </div>
                 <div className='technology'>
                    <span>HTML</span>
                    <span>90%</span>
                 </div>
                 <div className='outer-div'>
                    <div className='inner-div'>

                    </div>
                 </div>
                 
               </div>
            </div>
               
                <div>
                <div className='html'>
                 <div className="css-icon">
                   <BiLogoCss3/>
                 </div>
                 <div className='technology'>
                    <span>CSS</span>
                    <span className='css-percentage'>80%</span>
                 </div>
                 <div className='outer-div'>
                    <div className='inner-div-css'>

                    </div>
                 </div>
                 
               </div>
                </div>
               
               <div>
               <div className='html'>
                 <div className="js-icon">
                   <SiJavascript/>
                 </div>
                 <div className='technology'>
                    <span>Javascript</span>
                    <span className='js-percentage'>75%</span>
                 </div>
                 <div className='outer-div'>
                    <div className='inner-div-js'>

                    </div>
                 </div>
                 
               </div>
               </div>
               
                 <div>
                 <div className='html'>
                 <div className="react-icon">
                   <RiReactjsLine/>
                 </div>
                 <div className='technology'>
                    <span>React-js</span>
                    <span>90%</span>
                 </div>
                 <div className='outer-div'>
                    <div className='inner-div'>

                    </div>
                 </div>
                 
               </div>
                 </div>
               <div>
               <div className='html'>
                 <div className="java-icon">
                   <FaJava/>
                 </div>
                 <div className='technology'>
                    <span>Java</span>
                    <span className='java-percentage'>60%</span>
                 </div>
                 <div className='outer-div'>
                    <div className='inner-div-java'>

                    </div>
                 </div>
                 
               </div>
               </div>
               
               
           </div>
           <div className="s-right">
             <h2> <abbr title="">Professional Skills</abbr></h2>
             <div className='s-rows'>
                <div className='top-row'>
                <div className='circle'>
                    <CircularProgressbar value={percentage} text={`${percentage}%`}  styles={buildStyles({
                        pathColor: 'rgb(0, 255, 255)',
                        textColor: 'rgb(255, 255, 255)',
                        trailColor:'rgb(255, 255, 255)' ,
                        backgroundColor: 'rgb(255, 255, 255)',

                    })}/>
                    <h4 className='s-text1'>Creativity</h4>
                   </div>
                   <div className='circle'>
                    <CircularProgressbar value={percentage2} text={`${percentage2}%`}  styles={buildStyles({
                        pathColor: 'rgb(0, 255, 255)',
                        textColor: 'rgb(255, 255, 255)',
                        trailColor:'rgb(255, 255, 255)' ,
                        backgroundColor: 'rgb(255, 255, 255)',

                    })}/>
                    <h4 className='s-text2'>Communication</h4>
                   </div>
                </div>
                <div className="bottom-row">
                   <div className='circle'>
                    <CircularProgressbar value={percentage3} text={`${percentage3}%`}  styles={buildStyles({
                        pathColor: 'rgb(0, 255, 255)',
                        textColor: 'rgb(255, 255, 255)',
                        trailColor:'rgb(255, 255, 255)' ,
                        backgroundColor: 'rgb(255, 255, 255)',

                    })}/>
                    <h4 className='s-text3'>ProblemSolving</h4>
                   </div>
                   <div className='circle'>
                    <CircularProgressbar value={percentage4} text={`${percentage4}%`}  styles={buildStyles({
                        pathColor: 'rgb(0, 255, 255)',
                        textColor: 'rgb(255, 255, 255)',
                        trailColor:'rgb(255, 255, 255)' ,
                        backgroundColor: 'rgb(255, 255, 255)',

                    })}/>
                    <h4 className='s-text4'>Teamwork</h4>
                   </div>
                </div>
             </div>
             

           </div>
        </div>
    </div>
  )
}

export default Skills